@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-Med-v3.2.ttf');
  /* src: url('helvethaica/DB-Helvethaica-X-v3.2.ttf'); */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-Med-It-v3.2.ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-Med-It-v3.2.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'helvethaica';
  src: url('helvethaica/DB-Helvethaica-X-v3.2.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'THSarabunNew';
  src: url('THSarabunNew.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBold';
  src: url('THSarabunNewBold.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewBoldItalic';
  src: url('THSarabunNew BoldItalic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'THSarabunNewItalic';
  src: url('THSarabunNew Italic.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('Bai_Jamjuree/BaiJamjuree-Medium.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Prompt';
  src: url('Bai_Jamjuree/BaiJamjuree-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Prompt';
  src: url('Bai_Jamjuree/BaiJamjuree-SemiBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Charmonman';
  src: url('Charmonman/Charmonman-Regular.ttf') format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: 'Charmonman';
  src: url('Charmonman/Charmonman-Bold.ttf') format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'Parisienne';
  src: url('Parisienne/Parisienne-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Sriracha';
  src: url('Sriracha/Sriracha-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'DancingScript';
  src: url('Dancing_Script/DancingScript-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'AckotivThaiRegular';
  src: url('AckotivThaiRegular.ttf');
  font-weight: normal;
}
